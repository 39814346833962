<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <!-- Card Title on the left -->
            <h5 class="mb-1">
              {{ task.name }}
            </h5>

            <!-- Edit Icon on the right -->
            <feather-icon
              :id="`project-edit-${task.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'task-configuration-id', params: { id: task.id }})"
            />
          </div>
          <b-media no-body>
            <b-media-aside
              vertical-align="center"
              class="mr-50"
            >
              <feather-icon
                icon="CheckSquareIcon"
                size="24"
                class="mr-50 text-primary"
              />
            </b-media-aside>
            <b-media-body>
              <small class="text-muted">{{ task.taskType.name }}</small>
              <span class="text-muted ml-75 mr-50">|</span>
              <small class="text-muted">{{ task.taskFrequency.name }}</small>
            </b-media-body>
          </b-media>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content  mt-2"
            v-html="task.description"
          />
          <!-- eslint-enable -->
          <hr class="my-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-1">
                <b-link class="mr-50">
                  <feather-icon
                    icon="StarIcon"
                    size="21"
                    class="mr-50 text-primary"
                  />
                </b-link>
                <b-link>
                  <div class="text-body">
                    {{ task.points }}
                  </div>
                </b-link>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <div>
              <b-form
                ref="form"
                class="repeater-form"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="24"
                  class="mr-50 mb-1 text-primary"
                  @click="repeateAgain"
                />
                <feather-icon
                  icon="EyeIcon"
                  size="24"
                  class="mr-50 mb-1 text-primary"
                  @click="openTaskModel"
                />
                <!-- Draggable Subtasks -->
                <draggable
                  v-model="task.subtasks"
                  group="subtasks"
                  handle=".drag-handle"
                  @end="updateSequence"
                >
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in task.subtasks"
                    :id="index.toString()"
                    :key="index"
                    ref="row"
                    :style="getBorderColorStyle(task.subtasks[index].tag)"
                  >

                    <!-- Name -->
                    <b-col
                      md="3"
                    >
                      <b-form-group
                        :label="$t('NAME')"
                        label-for="name"
                      >
                        <b-form-input
                          :id="index.toString()"
                          :ref="'name-' + index"
                          v-model="task.subtasks[index].name"
                          type="text"
                          maxlength="50"
                          :placeholder="$t('NAME')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Type -->
                    <b-col md="2">
                      <b-form-group
                        :label="$t('TYPE')"
                        label-for="id-type"
                      >
                        <v-select
                          :id="index.toString()"
                          v-model="task.subtasks[index].subtaskTypeId"
                          :options="allSubTaskTypes"
                          label="name"
                          :clearable="false"
                          :reduce="type => type.id"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Tag -->
                    <b-col md="2">
                      <b-form-group
                        :label="$t('TAG')"
                        label-for="id-type"
                      >
                        <v-select
                          :id="index.toString()"
                          v-model="task.subtasks[index].tag"
                          :options="tagOptions"
                          label="name"
                          :clearable="true"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Description -->
                    <b-col
                      md="2"
                    >
                      <b-form-group
                        :label="$t('DESCRIPTION')"
                        label-for="description"
                      >
                        <b-form-input
                          :id="index.toString()"
                          v-model="task.subtasks[index].description"
                          type="text"
                          maxlength="300"
                          :placeholder="$t('DESCRIPTION')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Prompt -->
                    <b-col
                      md="3"
                    >
                      <b-form-group
                        :label="$t('PROMPT')"
                        label-for="prompt"
                      >
                        <b-form-input
                          :id="index.toString()"
                          v-model="task.subtasks[index].prompt"
                          type="text"
                          maxlength="50"
                          :placeholder="$t('PROMPT')"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Minimum Value -->
                    <b-col
                      md="2"
                    >
                      <b-form-group
                        :label="$t('MINIMUM_VALUE')"
                        label-for="id-minimum"
                      >
                        <b-form-spinbutton
                          id="id-minimum"
                          v-model="task.subtasks[index].minValue"
                          min="0"
                          max="100"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Maximum Value -->
                    <b-col
                      md="2"
                    >
                      <b-form-group
                        :label="$t('MAXIMUM_VALUE')"
                        label-for="id-maximum"
                      >
                        <b-form-spinbutton
                          id="id-maximum"
                          v-model="task.subtasks[index].maxValue"
                          min="0"
                          max="100"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Points -->
                    <b-col
                      md="2"
                    >

                      <b-form-group
                        :label="$t('POINTS')"
                        label-for="id-task-points"
                      >

                        <b-form-input
                          id="id-task-points"
                          v-model="task.subtasks[index].points"
                          maxlength="3"
                          type="number"
                          trim
                          :placeholder="$t('POINTS')"
                        />

                      </b-form-group>
                    </b-col>
                    <!-- Charity Amount -->
                    <b-col
                      md="2"
                    >
                      <b-form-group
                        :label="$t('CHARITY_AMOUNT')"
                        label-for="id-charityAmount"
                      >
                        <b-form-input
                          :id="index.toString()"
                          v-model="task.subtasks[index].charityWeight"
                          type="number"
                          :placeholder="$t('CHARITY_AMOUNT')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Referance ID -->
                    <b-col
                      md="2"
                    >
                      <b-form-group
                        :label="$t('REFERENCE_ID')"
                        label-for="id-reference-number"
                      >
                        <b-form-input
                          id="id-reference-number"
                          v-model="task.subtasks[index].externalReference"
                          maxlength="45"
                          :placeholder="$t('REFERENCE_ID')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Mandatory -->
                    <b-col
                      md="2"
                    >
                      <b-form-group
                        :label="$t('MANDATORY')"
                        label-for="isMandatory"
                      >
                        <b-form-checkbox
                          :id="'chk_isMandatory' + index.toString()"
                          :key="index"
                          v-model="task.subtasks[index].isMandatory"
                          class="mt-0 mt-md-2"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Add & Remove Button -->
                    <b-col
                      md="2"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-primary"
                        class="mt-2 mr-2"
                        @click="repeateAgain"
                      >
                        <feather-icon
                          icon="PlusCircleIcon"
                        />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-2"
                        @click="
                          removeItem(index, task.subtasks[index])
                        "
                      >
                        <feather-icon
                          icon="Trash2Icon"
                        />
                      </b-button>
                    </b-col>
                    <!-- Drag Handle -->
                    <b-col
                      md="1"
                      class="drag-handle d-flex align-items-center justify-content-center"
                    >
                      <feather-icon
                        icon="MoveIcon"
                        size="16"
                        class="cursor-move"
                      />
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>

                  </b-row>
                </draggable>
              </b-form>
            </div>
            <!-- Submit and Reset -->
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
                @click="handleSubmit(false)"
              >
                {{

                  $t("BUTTON.SAVE_CHANGES")
                }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
                @click="handleSubmit(true)"
              >
                {{

                  $t("BUTTON.SAVE_AND_CLOSE")
                }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="navigateToList"
              >
                {{ $t("BUTTON.CANCEL") }}
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <task-view-model
      :index="taskIndex"
      :all-content-tasks="tasks"
    />
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormSpinbutton,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import draggable from 'vuedraggable'
import TaskViewModel from './TaskViewModel.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormSpinbutton,
    BFormCheckbox,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    draggable,
    TaskViewModel,
  },
  data() {
    return {
      task: {
        id: 0,
        contentId: 0,
        sequence: 0,
        taskTypeId: 0,
        taskFrequencyId: 0,
        name: '',
        description: '',
        externalReference: '',
        points: 0,
        language: '',
        charityWeight: 0,
        taskFrequency: {},
        taskType: {},
        subtasks: [],
      },
      tagOptions: constants.TAGS,
      tagColors: constants.TAG_COLORS,
      required,
      nextTodoId: 2,
      taskIndex: -1,
      tasks: [],
    }
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
      errorMessage,
    } = mixinAlert()

    return {
      errorMessage,
      successMessage,
      showErrorMessage,
    }
  },
  computed: {
    ...mapState('task', {
      allSubTaskTypes: state => state.allSubTaskTypes,
    }),
  },
  async created() {
    await this.fetchSubTaskTypes()
    await this.fetchTasks()
  },
  methods: {
    ...mapActions('task', [
      'fetchTaskByID',
      'createSubTask',
      'updateSubTask',
      'deleteSubTask',
      'fetchSubTaskTypes']),
    async fetchTasks() {
      this.fetchTaskByID(router.currentRoute.params.id).then(response => {
        if (response && response.data) {
          this.task = response.data
          this.tasks.push(this.task)
          if (this.task.subtasks.length === 0) {
            this.addSubtask()
          }
        }
      })
    },
    navigateToList() {
      this.$router.push({ name: 'task-list' })
    },
    handleSubmit(shouldRedirect) {
      if (!this.validateSubtasks()) {
        this.errorMessage(this.$i18n.t('MESSAGE.SUBTASK_REQUIRED'))
        return
      }
      const tasksToSave = this.task.subtasks.map(subtask => {
        const payload = {
          ...subtask,
          taskId: this.task.id,
        }
        const saveOperation = subtask.id ? this.updateSubTask : this.createSubTask
        return saveOperation(payload)
      })
      Promise.all(tasksToSave)
        .then(responses => {
          if (responses.every(response => response)) {
            this.successMessage(this.$i18n.t('MESSAGE.SUBTASK_CREATED'))
            if (shouldRedirect) {
              this.$router.push({ name: 'task-list' })
            } else {
              this.fetchTasks()
            }
          }
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    updateSequence() {
      this.task.subtasks.forEach((subtask, index) => {
        // eslint-disable-next-line no-param-reassign
        subtask.sequence = index + 1
      })
    },
    getBorderColorStyle(tag) {
      const color = this.tagColors[tag] || '#d3d3d3'
      return {
        border: `2px solid ${color}`,
        padding: '16px',
        marginBottom: '10px',
        borderRadius: '4px',
      }
    },
    validateSubtasks() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, subtask] of this.task.subtasks.entries()) {
        if (!subtask.name || !subtask.subtaskTypeId) {
          this.$nextTick(() => {
            const nameInput = this.$refs[`name-${index}`]
            if (nameInput && nameInput[0]) {
              nameInput[0].focus()
            }
          })
          return false
        }
      }
      return true
    },
    repeateAgain() {
      this.addSubtask()
    },
    removeItem(index, data) {
      this.task.subtasks.splice(index, 1)
      if (data.id) {
        this.deleteSubTask(data.id)
      }
    },
    openTaskModel() {
      this.taskIndex = 0
      this.$bvModal.show('task-view-modal')
    },
    addSubtask() {
      const maxSequence = this.task.subtasks.reduce((max, subtask) => (subtask.sequence > max ? subtask.sequence : max), 0)
      const newSubtask = {
        id: 0,
        taskId: this.task.id,
        name: '',
        description: '',
        sequence: maxSequence + 1,
        charityWeight: 0,
        points: 0,
        minValue: 0,
        maxValue: 0,
        prompt: '',
        subtaskTypeId: 0,
        tag: '',
        isMandatory: false,
      }
      this.task.subtasks.push(newSubtask)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.subtask-row {
  border: 1px solid #d3d3d3;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
