<template>
  <b-modal
    id="task-view-modal"
    ref="task-view-modal"
    :title="name"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
  >
    <div>
      <b-card>
        <!-- Check if there are no tasks -->
        <div
          v-if="questionsList.length === 0"
          class="text-center mt-3"
        >
          <p class="text-muted">
            {{ $t('MESSAGE.NO_TASK_SUBTASK_AVAILABLE') }}
          </p>
        </div>
        <!-- Tasks List -->
        <b-row v-else>
          <b-col>
            <div
              v-for="question in questionsList"
              :key="question.questionID"
            >
              <b-card class="mb-1 p-1 custom-card">
                <!-- Display the question statement -->
                <h5>{{ question.statement }}</h5>
                <p v-if="question.description">
                  {{ question.description }}
                </p>

                <!-- Render options as radio buttons for radio-selection -->
                <div v-if="question.questionType === 'radio-selection'">
                  <label>{{ question.prompt }}</label>
                  <b-form-radio-group
                    v-model="question.selectedOption"
                    :options="getOptionsForRadio(question)"
                  />
                </div>

                <!-- Render options as checkboxes for multiple-selection -->
                <div v-else-if="question.questionType === 'multiple-selection'">
                  <div
                    v-for="option in question.options"
                    :key="option.id"
                  >
                    <b-form-checkbox v-model="option.isSelected">
                      {{ option.name }}
                    </b-form-checkbox>
                  </div>
                </div>

                <!-- Render text input for text-based questions (questionType === 3) -->
                <!-- If questionType = 1, show a checkbox -->
                <div
                  v-else-if="question.questionType === 1"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox v-model="question.answer">
                    {{ question.prompt }}
                  </b-form-checkbox>
                </div>

                <!-- If questionType = 2, show a text input -->
                <div v-else-if="question.questionType === 2 || question.questionType === 'only-header'">
                  <b-form-input
                    v-model="question.answer"
                    type="text"
                    :placeholder="question.prompt"
                  />
                </div>

                <!-- If questionType = 3, show a number input -->
                <div v-else-if="question.questionType === 3 || question.questionType === 4">
                  <b-form-input
                    v-model="question.answer"
                    type="number"
                    :placeholder="question.prompt"
                  />
                </div>
                <!-- If questionType = 6, show a number input -->
                <div v-else-if="question.questionType === 6">
                  <b-form-rating
                    v-model="question.answer"
                    inline
                    no-border
                    show-value
                    :stars="question.maxValue"
                    variant="primary"
                  />
                </div>
                <!-- Handle unknown question types -->
                <div v-else>
                  <p class="text-muted">
                    {{ $t('MESSAGE.UNSUPPORTED_QUESTION_TYPE') }}
                  </p>
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BRow,
  BCol,
  BFormRating,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import {
  mapSubtasksToQuestions,
} from '@/constants/utils'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    BRow,
    BCol,
    BFormRating,
  },
  props: {
    index: {
      type: Number,
      default: -1,
      required: true,
    },
    allContentTasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      questionsList: [],
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      mapSubtasksToQuestions,
    }
  },
  watch: {
    index: {
      handler(newIndex) {
        if (newIndex !== null && newIndex !== undefined && this.allContentTasks.length > 0) {
          this.name = this.allContentTasks[this.index].name
          this.questionsList = mapSubtasksToQuestions(this.allContentTasks[this.index].subtasks || [])
        } else {
          this.questionsList = []
        }
      },
      deep: true,
    },
  },
  methods: {
    getOptionsForRadio(question) {
      return question.options.map(option => ({
        text: option.name,
        value: option.id,
      }))
    },
    resetModal() {
      this.nameState = null
    },
  },
}
</script>

<style lang="scss">

.custom-card {
  border: 2px solid #ff6400;
  border-radius: 10px;
}

</style>
